import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import '../mobile-connection/nn-mobile-connection.js';
import '../../atoms/mobile-button/nn-mobile-button.js';

/**
 * The navigation molecule contains the brand logo and categories' links navigation with dropdown-menu if needed.
 * Under 767px wide, mobile navigation is activated with transition-side movement.
 * In connected mode, user block is shown with : user, userID, customer website link and one logout button.
 *
 * connected - The Connected boolean variable. If set at true, connected block is shown.
 * open - Value of mobile navigation opening.
 * drop - Value of dropdown-menu opening.
 * activeItem - Value of active item in navigation. In order : internet, mobile, securite, domaine. Set null if no item is active.
 * user - The user login. Shown in connected mode only.
 * userId - The user ID. Shown in connected mode only.
 * baseUrl - The base Url. Used to build others Url in component.
 * customerUrl - The customer website URL. Url used in connected mode only.
 * loginUrl - The login Connexion URL. URL used in not connected mode only.
 * logoutUrl - The logout Connexion URL. Shown in connected mode only.
 * webmailUrl - The webmail website specific Url.
 * assistanceUrl - The assistance website specific Url.
 *
 */
@customElement('nn-navigation')
export class Navigation extends LitElement {
  static styles = css`
    * {
      box-sizing: border-box;
    }
    .overflowed {
      overflow: hidden;
    }
    svg:not(:root) {
      overflow: hidden;
    }
    .img-responsive {
      color: var(--nn-navigation_img-responsive_color, #323232);
      display: inline-block;
      font-size: var(--nn-navigation_img-responsive_font-size, 0.75em);
      object-fit: cover;
      position: relative;
    }
    .custom-flex {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }
    .container {
      margin: 0 auto;
      max-width: var(--nn-navigation_container_max-width, 1470px);
      padding: 0 15px;
      width: 100%;
    }
    .global-header--navbar {
      background: var(
        --nn-navigation_global-header--navbar_background,
        #f8f8f8
      );
      border: 0;
      border-radius: 0;
      font-family: 'font-heavy';
      font-size: var(--nn-navigation_global-header--navbar_font-size, 16px);
      line-height: 1.35;
      box-sizing: border-box;
      margin: 0 auto;
      padding: 0;
      width: 100%;
      position: relative;
      z-index: 1000;
    }
    .global-header--toggle {
      display: var(--nn-navigation_global-header--toggle_display, none);
      margin: var(--nn-navigation_global-header--toggle_margin, 0);
      padding: var(--nn-navigation_global-header--toggle_padding, 0);
    }
    .global-header--toggle_content {
      display: flex;
      height: var(--nn-navigation_global-header--toggle_content_height, 70px);
      justify-content: flex-start;
      padding: 0;
      width: 100%;
      transform: inherit !important;
    }
    .global-header--toggle_content__header--logo {
      display: inline-flex;
      align-items: center;
      margin-right: 15px;
    }
    .global-header--toggle_content__header--logo svg {
      height: 32px;
      width: 177px;
    }
    .global-header--toggle_content__body {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      list-style-type: none;
      margin: var(
        --nn-navigation_global-header--toggle_content__body_margin,
        0
      );
      padding: var(
        --nn-navigation_global-header--toggle_content__body_padding,
        0
      );
      z-index: 1;
    }
    .global-header--toggle_content__body--item,
    .has-submenu--content li {
      display: block;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    .global-header--toggle_content__body--item a,
    .global-header--toggle_content__body--item a:hover {
      align-items: center;
      background: none;
      color: var(
        --nn-navigation_global-header--toggle_content__body--item_a_color,
        #595959
      );
      display: var(
        --nn-navigation_global-header--toggle_content__body--item_a_display,
        flex
      );
      font-family: 'font-light';
      font-size: var(
        --nn-navigation_global-header--toggle_content__body--item_a_font-size,
        0.938em
      );
      font-weight: 700;
      height: var(
        --nn-navigation_global-header--toggle_content__body--item_a_height,
        70px
      );
      margin: var(
        --nn-navigation_global-header--toggle_content__body--item_a_margin,
        0
      );
      padding: var(
        --nn-navigation_global-header--toggle_content__body--item_a_padding,
        0 10px
      );
      text-decoration: none;
      text-transform: var(
        --nn-navigation_global-header--toggle_content__body--item_a_text-transform,
        uppercase
      );
    }
    .global-header--toggle_content__body--item.active a,
    .global-header--toggle_content__body--item:hover a {
      color: var(
        --nn-navigation_global-header--toggle_content__body--item_a--hover_color,
        #595959
      );
      background: none;
      position: relative;
      transition: var(
        --nn-navigation_global-header--toggle_content__body--item_a_transition,
        0.3s
      );
    }
    .global-header--toggle_content__body--item.active > a:after,
    .global-header--toggle_content__body--item:hover > a:after {
      background: var(
        --nn-navigation_global-header--toggle_content__body--item-active--after_background,
        #e31b22
      );
      border: 2px solid
        var(
          --nn-navigation_global-header--toggle_content__body--item-active--after_border-color,
          #e31b22
        );
      content: '';
      display: block;
      height: 1px;
      width: calc(100% - 25px);
      position: absolute;
      left: 10px;
      right: 0;
      bottom: -1px;
    }
    .has-submenu {
      padding-right: var(--nn-navigation_has-submenu_padding-right, 20px);
      position: var(--nn-navigation_has-submenu_position, relative);
    }
    .has-submenu.active a:after,
    .has-submenu:hover a:after {
      width: calc(100% - 5px);
    }
    .has-submenu:after {
      border-left: 1px solid
        var(--nn-navigation_has-submenu--after_border-left-color, #2c2c2c);
      border-bottom: 1px solid
        var(--nn-navigation_has-submenu--after_border-bottom-color, #2c2c2c);
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      position: absolute;
      right: 12px;
      top: calc(50% - 7px);
      transform: rotate(-45deg);
      transition: var(--nn-navigation_has-submenu--after_transition, 0.3s);
    }
    .has-submenu--content {
      background-clip: padding-box;
      background: var(--nn-navigation_has-submenu--content_background, #fff);
      border: 0;
      border-radius: var(
        --nn-navigation_has-submenu--content_border-radius,
        0 0 20px 20px
      );
      box-shadow: var(
        --nn-navigation_has-submenu--content_box-shadow,
        0 6px 12px rgba(0, 0, 0, 0.18)
      );
      display: none;
      font-size: var(--nn-navigation_has-submenu--content_font-size, 0.875em);
      height: 0;
      list-style: none;
      margin: 0;
      min-width: var(--nn-navigation_has-submenu--content_min-width, 160px);
      opacity: 0;
      overflow: hidden;
      padding: var(--nn-navigation_has-submenu--content_padding, 20px 30px);
      text-align: left;
      width: var(--nn-navigation_has-submenu--content_width, 389px);
      position: var(--nn-navigation_has-submenu--content_position, absolute);
      top: 100%;
      left: 0;
      z-index: 1000;
    }
    .has-submenu--content[aria-expanded='false'] {
      display: none;
    }
    .has-submenu .active + .has-submenu--content {
      display: block;
      height: auto;
      opacity: 1;
      transition: height 0ms
          var(
            --nn-navigation_has-submenu-active-has-submenu--content_transition,
            0.3s
          ),
        opacity
          var(
            --nn-navigation_has-submenu-active-has-submenu--content_transition,
            0.3s
          )
          0ms;
      left: 10px;
      top: 70px;
    }
    .has-submenu--content a,
    .has-submenu--content a:active,
    .has-submenu--content a:focus,
    .has-submenu--content a:visited,
    .has-submenu--content a:hover {
      color: var(--nn-navigation_has-submenu--content_a_color, #2f2f2f);
      display: block;
      font-size: var(
        --nn-navigation_has-submenu--content_a_font-size,
        1.2875em
      );
      font-weight: 400;
      height: auto;
      padding: var(--nn-navigation_has-submenu--content_a_padding, 10px 0);
      text-transform: inherit;
      position: relative;
      transition: padding
        var(--nn-navigation_has-submenu--content_a_transition, 0.3s) ease-in-out;
    }
    .has-submenu--content a:hover {
      background: var(
        --nn-navigation_has-submenu--content_a--hover_background,
        #f2f2f2
      );
      color: var(--nn-navigation_has-submenu--content_a--hover_color, #010101);
      padding-left: var(
        --nn-navigation_has-submenu--content_a--hover_padding-left,
        10px
      );
    }
    .active .has-submenu--content a:after,
    .has-submenu--content a:after {
      background: var(
        --nn-navigation_has-submenu--content_a--after_background,
        #edf0f5
      );
      border: 0;
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      right: inherit;
      bottom: -1px;
    }
    .dropdown-open.has-submenu--content li:last-child a:after {
      display: none;
    }
    .dropdown-open:after {
      transform: rotate(-225deg);
      top: calc(50% - 2px);
      transition: transform
        var(--nn-navigation_dropdown-open--after_transition, 0.3s);
    }
    @media (max-width: 991px) {
      :host {
        --nn-navigation_global-header--toggle_content__body--item_a_font-size: 0.75em;
        --nn-navigation_has-submenu--content_a_font-size: 1.15em;
      }
    }
    @media (max-width: 767px) {
      :host {
        --nn-navigation_has-submenu--content_a--hover_background: transparent;
        --nn-navigation_has-submenu--content_border-radius: 0;
        --nn-navigation_has-submenu--content_box-shadow: none;
        --nn-navigation_has-submenu--content_padding: 0 0 0 20px;
        --nn-navigation_has-submenu--content_position: static;
        --nn-navigation_has-submenu--content_width: 100%;
        --nn-navigation_has-submenu_padding-right: 0;
        --nn-navigation_global-header--toggle_display: flex;
        --nn-navigation_global-header--toggle_margin: 0 -15px;
        --nn-navigation_global-header--toggle_padding: 0 15px;
        --nn-navigation_global-header--toggle_content_height: auto;
        --nn-navigation_global-header--toggle_content__body_padding: 20px;
        --nn-navigation_global-header--toggle_content__body--item_a_color: #000;
        --nn-navigation_global-header--toggle_content__body--item_a_display: block;
        --nn-navigation_global-header--toggle_content__body--item_a_font-size: 1.1em;
        --nn-navigation_global-header--toggle_content__body--item_a_height: auto;
        --nn-navigation_global-header--toggle_content__body--item_a_margin: 5px
          0;
        --nn-navigation_global-header--toggle_content__body--item_a_padding: 10px
          0;
        --nn-navigation_global-header--toggle_content__body--item_a_text-transform: none;
        --nn-navigation_global-header--toggle_content__body--item_a--hover_color: #000;
      }
      .global-header--toggle_content__header--logo,
      .active .has-submenu--content a:after,
      .has-submenu--content a:after {
        display: none;
      }
      .global-header--toggle {
        align-items: center;
        background: var(--nn-navigation_global-header--toggle_background, #fff);
        box-shadow: var(
          --nn-navigation_global-header--toggle_box-shadow,
          0 0 10px rgba(0, 0, 0, 0.1)
        );
        height: var(--nn-navigation_global-header--toggle_height, 56px);
        justify-content: space-between;
      }
      .global-header--toggle_button {
        background: none;
        border: 0;
        box-shadow: none;
        cursor: pointer;
        height: var(--nn-navigation_global-header--toggle_button_height, 25px);
        margin: var(--nn-navigation_global-header--toggle_button_margin, 0);
        padding: var(--nn-navigation_global-header--toggle_button_padding, 0);
        width: var(--nn-navigation_global-header--toggle_button_width, 27px);
        position: relative;
        z-index: 10;
      }
      .global-header--toggle_button span {
        border-radius: 2px;
        position: absolute;
        display: block;
        height: 5px;
        width: 100%;
        background: var(
          --nn-navigation_global-header--toggle_button_span_background,
          #000
        );
        transform: rotate(0);
        transition: all 200ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
      }
      .global-header--toggle_button .one {
        top: 0;
      }
      .global-header--toggle_button .two {
        top: 10px;
      }
      .global-header--toggle_button .three {
        top: calc(100% - 5px);
      }
      .collapse .one {
        transform: translateY(10px) rotate(45deg);
      }
      .collapse .two {
        opacity: 0;
      }
      .collapse .three {
        transform: translateY(-10px) rotate(-45deg);
      }
      .global-header--toggle_content {
        background: rgba(249, 249, 249, 0.95);
        position: fixed;
        top: var(--nn-navigation_global-header--toggle_content_top, 56px);
        right: -120%;
        left: inherit;
        bottom: 0;
        z-index: 999;
        transition: all var(--nn-transition, 0.3s) ease-in-out;
      }
      .collapse .global-header--toggle_content {
        align-items: flex-end;
        border: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-y: auto !important;
        overflow-x: hidden;
        max-height: var(
          --nn-navigation_collapse_global-header--toggle_content_max-height,
          calc(100% - 56px)
        );
        padding: 0;
        right: 0;
      }
      .global-header--toggle_content__header {
        background: var(
          --nn-navigation_global-header--toggle_content__header_background,
          #fff
        );
        margin: 0;
        max-width: 380px;
        padding: 0;
        position: relative;
        width: 100%;
      }
      .collapse .global-header--toggle_content__header:after {
        background: var(
          --nn-navigation_collapse_global-header--toggle_content__header--after_background,
          #dbdbdb
        );
        content: '';
        height: 100%;
        width: 1px;
        position: absolute;
        top: 0;
        right: 380px;
      }
      .global-header_header__content {
        background: var(
          --nn-navigation_global-header_header__content_background,
          #fff
        );
        border-radius: 0;
        display: flex;
        flex-wrap: wrap;
        padding: var(
          --nn-navigation_global-header_header__content_padding,
          20px 16px
        );
        text-align: left;
      }
      .global-header_header__content .anchor {
        align-items: center;
        display: inline-flex;
        flex-basis: auto;
        justify-content: center;
        line-height: 1;
        padding: var(
          --nn-navigation_global-header_header__content_anchor_padding,
          0 3px
        );
        width: 25%;
      }
      .global-header--toggle_content__body {
        background: var(
          --nn-navigation_global-header--toggle_content__body_color,
          #fff
        );
        flex-direction: column;
        height: var(
          --nn-navigation_global-header--toggle_content__body_height,
          100vh
        );
        margin: var(
          --nn-navigation_global-header--toggle_content__body_margin,
          0
        );
        max-width: var(
          --nn-navigation_global-header--toggle_content__body_max-width,
          380px
        );
        order: 2;
        width: 100%;
        position: relative;
      }
      .global-header--toggle_content__body:before {
        background: var(
          --nn-navigation_global-header--toggle_content__body--before_background,
          #dbdbdb
        );
        content: '';
        height: 100%;
        width: 1px;
        position: absolute;
        top: 0;
        right: 100%;
      }
      .global-header--toggle_content__body--item,
      .has-submenu--content li {
        width: 100%;
      }
      .has-submenu--content > li {
        flex-shrink: 0;
        flex-basis: auto;
        width: 100%;
      }
      .global-header--toggle_content__body--item.has-submenu--content > li > a,
      .global-header--toggle_content__body--item.has-submenu--content
        > li
        a:hover {
        color: var(
          --nn-navigation_global-header--toggle_content__body--item-has-submenu--content_li_a_color,
          #000
        );
        display: block;
        font-size: var(
          --nn-navigation_global-header--toggle_content__body--item-has-submenu--content_li_a_font-size,
          1em
        );
        height: auto;
        margin: var(
          --nn-navigation_global-header--toggle_content__body--item-has-submenu--content_li_a_margin,
          0
        );
        padding: var(
          --nn-navigation_global-header--toggle_content__body--item-has-submenu--content_li_a_padding,
          16px 10px
        );
        text-align: left;
        text-transform: none;
        width: 100%;
      }
      .has-submenu--content > li > a:after {
        align-items: center;
        background: none;
        border: 0;
        color: var(
          --nn-navigation_has-submenu--content_li_a--after_color,
          #ae1720
        );
        content: '';
        display: flex;
        font-size: var(
          --nn-navigation_has-submenu--content_li_a--after_font-size,
          1.25em
        );
        height: var(
          --nn-navigation_has-submenu--content_li_a--after_height,
          14px
        );
        justify-content: center;
        text-align: left;
        text-transform: none;
        width: var(--nn-navigation_has-submenu--content_li_a--after_width, 8px);
        position: absolute;
        left: inherit;
        top: var(
          --nn-navigation_has-submenu--content_li_a--after_top,
          calc(50% - 7px)
        );
        right: 0;
      }
      .has-submenu--content > li:last-child > a:after {
        display: none;
      }
      .global-header--toggle_content__body--item > a,
      .global-header--toggle_content__body--item > a:active,
      .global-header--toggle_content__body--item > a:focus,
      .global-header--toggle_content__body--item > a:visited,
      .global-header--toggle_content__body--item > a:hover {
        text-align: left;
        width: 100%;
        position: relative;
      }
      .global-header--toggle_content__body--item.active > a:after,
      .global-header--toggle_content__body--item:hover > a:after,
      .global-header--toggle_content__body--item > a:after {
        background: transparent;
        border: solid
          var(
            --nn-navigation_global-header--toggle_content__body--item-active--after_border-color,
            #ae1720
          );
        border-width: 0 0 2px 2px;
        content: '';
        height: 6px;
        width: 6px;
        position: absolute;
        left: inherit;
        bottom: inherit;
        top: calc(50% - 4px);
        right: 0;
        transform: rotate(225deg);
        transition: transform
          var(
            --nn-navigation_global-header--toggle_content__body--item-active--after_transition,
            0.3s
          );
      }
      .global-header--toggle_content__body--item.has-submenu:after {
        display: none;
      }
      .global-header--toggle_content__body--item
        .has-submenu--toggle[aria-expanded='true']:after {
        transform: rotate(315deg);
      }
      .connected-mode .global-header_header__content {
        background: var(
          --nn-navigation_connected-mode_global-header_header__content_background,
          #edf0f5
        );
        border-radius: var(
          --nn-navigation_connected-mode_global-header_header__content_border-radius,
          0 0 30px 30px
        );
        display: flex;
        flex-wrap: wrap;
        padding: var(
          --nn-navigation_connected-mode_global-header_header__content_padding,
          20px 15px
        );
        text-align: left;
      }
      .connected-button {
        background: var(--nn-navigation_connected-button_background, #fff);
      }
    }
    @media (min-width: 768px) {
      .global-header--toggle_content__header {
        display: none;
      }
    }
    @media (max-width: 360px) {
      .mobile-user--name {
        font-size: var(--nn-navigation_mobile-user--name_font-size, 1.35em);
        max-width: var(--nn-navigation_mobile-user--name_min-width, 285px);
      }
    }
    .menu-open {
      right: 0;
    }
    .menu-close {
      right: -120%;
    }
  `;

  @property({ type: Boolean }) open = false;

  @property({ type: Boolean }) drop = false;

  @property({ type: Boolean }) connected = false;

  @property({ type: String }) activeItem = 'internet';

  @property({ type: String }) user = '';

  @property({ type: String }) userId = '';

  @property({ type: String }) baseUrl = 'https://www.nordnet.com';

  @property({ type: String }) customerUrl = 'https://client.nordnet.com';

  @property({ type: String }) loginUrl = 'https://client.nordnet.com';

  @property({ type: String }) logoutUrl = 'https://nordnet.com/logout';

  @property({ type: String }) webmailUrl = 'https://nordnet.com/messagerie';

  @property({ type: String }) assistanceUrl = 'https://assistance.nordnet.com';

  render() {
    return html`
      <nav
        class=${classMap({
          'global-header--navbar': true,
          collapse: this.open,
        })}
        role="navigation"
        aria-label="Nordnet navigation principale"
      >
        <div class="container">
          <div class="global-header--toggle">
            <a
              id="global-header--toggle_logo"
              class="global-header--mobile_logo"
              href="${this.baseUrl}"
              aria-hidden="true"
              title="Nordnet"
            >
              <svg
                class="img-responsive"
                width="128"
                height="23"
                viewBox="0 0 170 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Nordnet</title>
                <path
                  d="M111.032 5.26732C111.032 5.26732 111.014 5.26732 111.032 5.26732C109.213 5.34022 107.754 6.05103 106.962 6.7254C106.944 6.7254 106.926 6.74362 106.908 6.74362C106.53 7.03524 106.296 6.7983 106.296 6.50668V5.8141H100.984V29.7084H106.314V14.1069C106.314 11.4824 108.403 9.36817 110.996 9.36817C113.553 9.36817 115.605 11.4277 115.677 13.9976C115.677 14.034 115.677 14.0705 115.677 14.1069V14.1252V29.7084H121.26V13.6695C121.26 7.14459 116.596 4.81167 111.032 5.26732Z"
                  fill="black"
                ></path>
                <path
                  d="M20.9062 5.26732C19.0875 5.34022 17.6289 6.05103 16.8366 6.7254C16.8186 6.7254 16.8006 6.74362 16.7826 6.74362C16.4045 7.03524 16.1704 6.7983 16.1704 6.50668V5.8141H10.8763V29.7084H16.2064V14.1069C16.2064 11.4824 18.2952 9.36817 20.8882 9.36817C23.4452 9.36817 25.498 11.4277 25.5701 13.9976C25.5701 14.034 25.5701 14.0705 25.5701 14.1069V14.1252V29.7084H31.1522V13.6695C31.1342 7.14459 26.4704 4.81167 20.9062 5.26732Z"
                  fill="black"
                ></path>
                <path
                  d="M64.6094 8.6209C64.5554 8.67558 64.4653 8.74849 64.3753 8.74849C64.2312 8.74849 64.1052 8.63913 64.0872 8.51155V5.8141H58.7751V29.7266H64.0872V21.3791C64.0872 13.469 67.0943 10.3159 72.1903 10.3159H72.9647V5.21264H71.7582C68.4629 5.19441 66.3741 6.16039 64.6094 8.6209Z"
                  fill="black"
                ></path>
                <path
                  d="M44.9637 5.21263C38.211 5.21263 32.7189 10.7533 32.7189 17.6063C32.7189 24.4411 38.193 30 44.9637 30C51.7163 30 57.1905 24.4593 57.1905 17.6063C57.1905 10.7533 51.7163 5.21263 44.9637 5.21263ZM44.9457 25.079C40.8581 25.079 37.5448 21.7254 37.5448 17.5881C37.5448 13.4508 40.8581 10.0972 44.9457 10.0972C49.0333 10.0972 52.3466 13.4508 52.3466 17.5881C52.3466 21.7436 49.0333 25.079 44.9457 25.079Z"
                  fill="black"
                ></path>
                <path
                  d="M92.5203 7.07169C92.5203 7.25395 92.3763 7.38153 92.2142 7.38153C92.1962 7.38153 92.1782 7.38153 92.1602 7.3633C90.1794 5.97813 87.8025 5.17618 85.2275 5.17618C78.7809 5.17618 73.5049 10.2248 73.0187 16.6221C73.0187 16.6586 73.0187 16.695 73.0007 16.7497C73.0007 16.7679 73.0007 16.7861 73.0007 16.8044C72.9827 16.9502 72.9827 17.1142 72.9827 17.26C72.9827 17.3329 72.9827 17.4058 72.9827 17.497C72.9827 17.5152 72.9827 17.5516 72.9827 17.5699C72.9827 17.6428 72.9827 17.7339 72.9827 17.8068C72.9827 17.9344 72.9827 18.062 73.0007 18.1713C73.0007 18.2078 73.0007 18.2625 73.0007 18.2989C73.3608 24.8056 78.6909 29.9818 85.2095 29.9818C87.7305 29.9818 90.0714 29.2163 92.0341 27.8858C92.0341 27.8858 92.1602 27.8311 92.2142 27.8311C92.3763 27.8311 92.5203 27.9769 92.5203 28.1409V29.7266H97.8504V0H92.5203V7.07169ZM85.1554 25.0243C84.021 25.0243 82.9406 24.7691 81.9862 24.3135C79.5192 23.0923 77.8086 20.5407 77.8086 17.5699C77.8086 13.4326 81.1219 10.079 85.2095 10.079C86.3439 10.079 87.4243 10.3341 88.3787 10.7898C90.8457 12.0109 92.5563 14.5626 92.5563 17.5334C92.5563 21.6707 89.243 25.0243 85.1554 25.0243Z"
                  fill="black"
                ></path>
                <path
                  d="M144.669 9.84204C142.418 7.01701 138.997 5.21263 135.143 5.21263C128.39 5.21263 122.898 10.7533 122.898 17.6063C122.898 17.9526 122.916 18.2989 122.934 18.627C123.15 18.8821 123.366 19.1555 123.583 19.3925C126.284 22.8372 130.443 25.0243 135.125 25.0243C131.073 25.0243 127.796 21.7072 127.796 17.6063C127.796 13.5055 131.073 10.1883 135.125 10.1883C137.142 10.1883 138.979 11.0267 140.311 12.3572C139.303 12.74 129.201 16.4581 129.201 16.4581C130.245 17.825 131.524 19.0462 133.018 20.0486L147.064 14.9089C146.668 13.0134 145.821 11.3001 144.669 9.84204Z"
                  fill="black"
                ></path>
                <path
                  d="M146.686 19.3742C143.985 22.8189 139.825 25.0243 135.143 25.0243C130.461 25.0243 126.302 22.8372 123.601 19.3925C123.366 19.1373 123.15 18.8821 122.952 18.627C123.474 24.9878 128.733 29.9818 135.143 29.9818C141.554 29.9818 146.83 24.9696 147.334 18.6087C147.118 18.8821 146.902 19.1373 146.686 19.3742Z"
                  fill="#f00"
                ></path>
                <path
                  d="M2.77309 29.9818C1.24249 29.9818 0 28.7242 0 27.175C0 25.6258 1.24249 24.3682 2.77309 24.3682C4.30369 24.3682 5.54618 25.6258 5.54618 27.175C5.54618 28.7242 4.30369 29.9818 2.77309 29.9818Z"
                  fill="black"
                ></path>
                <path
                  d="M164.333 27.175C164.333 25.6258 165.575 24.3682 167.106 24.3682C168.636 24.3682 169.879 25.6258 169.879 27.175C169.879 28.7242 168.636 29.9818 167.106 29.9818C165.575 29.9818 164.333 28.7242 164.333 27.175Z"
                  fill="black"
                ></path>
                <path
                  d="M159.038 24.5869L156.968 24.5687C155.527 24.5687 154.357 23.384 154.375 21.9259V10.6258C154.375 10.48 154.501 10.3706 154.645 10.3524H159.038V5.21265H154.663C154.519 5.21265 154.393 5.08506 154.375 4.93926V0.0182343H149.063V19.8664V22.1993V22.2175C149.063 26.9198 152.196 29.9818 156.716 29.9818H159.02V29.9636H159.038V24.5869Z"
                  fill="black"
                ></path>
              </svg>
            </a>
            <button
              id="global-header--toggle_button"
              class="global-header--toggle_button"
              type="button"
              @click="${this._toggle}"
              aria-label="navigation"
            >
              <span class="one" aria-hidden="true"></span>
              <span class="two" aria-hidden="true"></span>
              <span class="three" aria-hidden="true"></span>
            </button>
          </div>
          <div
            id="global-header--toggle_content"
            class=${classMap({
              'global-header--toggle_content': true,
              'menu-open': this.open,
              'menu-close': !this.open,
            })}
          >
            <div
              class=${classMap({
                'global-header--toggle_content__header': true,
                'connected-mode': this.connected,
              })}
            >
              <div class="global-header_header__content">
                <nn-mobile-button
                  id="mobile-header-telephone-link"
                  href="tel:3420"
                  text="Nous contacter au 3420"
                  class=${classMap({
                    anchor: true,
                    'connected-button': this.connected,
                  })}
                >
                  <svg
                    slot="icon"
                    class="img-responsive"
                    width="27"
                    height="27"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Nous contacter au 3420</title>
                    <path
                      d="M19.75 8.1462C20.2031 8.1462 20.6376 8.3262 20.958 8.64659C21.2784 8.96698 21.4584 9.40152 21.4584 9.85462V13.2715C21.4584 13.7246 21.2784 14.1591 20.958 14.4795C20.6376 14.7999 20.2031 14.9799 19.75 14.9799H18.8429C18.6347 16.6313 17.8309 18.15 16.5826 19.2509C15.3342 20.3518 13.727 20.9593 12.0625 20.9593V19.2509C13.4218 19.2509 14.7253 18.7109 15.6864 17.7498C16.6476 16.7886 17.1875 15.485 17.1875 14.1257V9.00041C17.1875 7.64111 16.6476 6.33748 15.6864 5.37631C14.7253 4.41514 13.4218 3.87516 12.0625 3.87516C10.7033 3.87516 9.39972 4.41514 8.4386 5.37631C7.47747 6.33748 6.93752 7.64111 6.93752 9.00041V14.9799H4.37502C3.92194 14.9799 3.48742 14.7999 3.16705 14.4795C2.84667 14.1591 2.66669 13.7246 2.66669 13.2715V9.85462C2.66669 9.40152 2.84667 8.96698 3.16705 8.64659C3.48742 8.3262 3.92194 8.1462 4.37502 8.1462H5.28215C5.49059 6.49493 6.2944 4.97647 7.54276 3.87574C8.79112 2.77501 10.3982 2.16766 12.0625 2.16766C13.7268 2.16766 15.3339 2.77501 16.5823 3.87574C17.8306 4.97647 18.6345 6.49493 18.8429 8.1462H19.75ZM8.44085 14.7962L9.34627 13.3475C10.1604 13.8575 11.1019 14.1272 12.0625 14.1257C13.0231 14.1272 13.9647 13.8575 14.7788 13.3475L15.6842 14.7962C14.5988 15.4763 13.3434 15.836 12.0625 15.8341C10.7817 15.836 9.52628 15.4763 8.44085 14.7962Z"
                      fill="#393939"
                    ></path>
                  </svg>
                </nn-mobile-button>

                <nn-mobile-button
                  id="mobile-header-assistance-link"
                  href="${this.assistanceUrl}"
                  text="Assistance"
                  class=${classMap({
                    anchor: true,
                    'connected-button': this.connected,
                  })}
                >
                  <svg
                    slot="icon"
                    class="img-responsive"
                    width="23"
                    height="24"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>assistance</title>
                    <path
                      d="M5.35827 1.60402L6.66132 4.80328C5.7239 5.29355 4.95876 6.05868 4.46849 6.99609L1.26919 5.69304C2.11709 3.89731 3.56251 2.45191 5.35827 1.60402ZM5.59651 1.00598C4.48945 1.45851 3.48369 2.12715 2.63801 2.97282C1.79232 3.8185 1.12368 4.82424 0.671143 5.93129L4.69701 7.57954C4.93051 7.00654 5.27618 6.48599 5.7137 6.04848C6.15123 5.61096 6.67178 5.2653 7.24478 5.0318L5.59651 1.00598Z"
                      fill="#393939"
                    ></path>
                    <path
                      d="M12.6418 1.60402C14.4375 2.45191 15.8829 3.89731 16.7308 5.69304L13.5315 6.99609C13.0413 6.05868 12.2761 5.29355 11.3387 4.80328L12.6418 1.60402ZM12.4035 1.00598L10.7552 5.0318C11.3283 5.2653 11.8488 5.61096 12.2863 6.04848C12.7239 6.48599 13.0695 7.00654 13.303 7.57954L17.3289 5.93129C16.8764 4.82424 16.2077 3.8185 15.362 2.97282C14.5163 2.12715 13.5106 1.45851 12.4035 1.00598Z"
                      fill="#393939"
                    ></path>
                    <path
                      d="M4.46849 11.6732C4.95876 12.6106 5.7239 13.3757 6.66132 13.866L5.35827 17.0652C3.56251 16.2173 2.11709 14.7719 1.26919 12.9762L4.46849 11.6732ZM4.69701 11.0897L0.671143 12.738C1.12368 13.845 1.79232 14.8508 2.63801 15.6964C3.48369 16.5421 4.48945 17.2107 5.59651 17.6633L7.24478 13.6375C6.67178 13.404 6.15123 13.0583 5.7137 12.6208C5.27618 12.1833 4.93051 11.6627 4.69701 11.0897Z"
                      fill="#393939"
                    ></path>
                    <path
                      d="M13.5315 11.6732L16.7308 12.9762C15.8829 14.7719 14.4375 16.2173 12.6418 17.0652L11.3387 13.866C12.2761 13.3757 13.0413 12.6106 13.5315 11.6732ZM13.303 11.0897C13.0695 11.6627 12.7239 12.1833 12.2863 12.6208C11.8488 13.0583 11.3283 13.404 10.7552 13.6375L12.4035 17.6633C13.5106 17.2107 14.5163 16.5421 15.362 15.6964C16.2077 14.8508 16.8764 13.845 17.3289 12.738L13.303 11.0897Z"
                      fill="#393939"
                    ></path>
                    <path
                      d="M8.99995 13.9827C8.39785 13.9842 7.80137 13.8669 7.24471 13.6375L5.59644 17.6633C7.77722 18.5581 10.2227 18.5581 12.4035 17.6633L10.7552 13.6375C10.1985 13.8669 9.60204 13.9842 8.99995 13.9827Z"
                      fill="#393939"
                    ></path>
                    <path
                      d="M8.99995 4.68607C9.60204 4.68453 10.1985 4.80184 10.7552 5.03128L12.4035 1.00546C10.2227 0.110647 7.77722 0.110647 5.59644 1.00546L7.24471 5.03128C7.80137 4.80184 8.39785 4.68453 8.99995 4.68607Z"
                      fill="#393939"
                    ></path>
                    <path
                      d="M13.303 7.5794C13.7634 8.70426 13.7634 9.96498 13.303 11.0898L17.3288 12.7381C18.2237 10.5573 18.2237 8.11191 17.3288 5.93115L13.303 7.5794Z"
                      fill="#393939"
                    ></path>
                    <path
                      d="M4.35177 9.33462C4.35023 8.73253 4.46755 8.13606 4.69699 7.5794L0.67112 5.93115C-0.223707 8.11191 -0.223707 10.5573 0.67112 12.7381L4.69699 11.0898C4.46755 10.5332 4.35023 9.93671 4.35177 9.33462Z"
                      fill="#393939"
                    ></path>
                  </svg>
                </nn-mobile-button>

                <nn-mobile-button
                  id="mobile-header-messagerie-link"
                  href="${this.webmailUrl}"
                  text="Messagerie"
                  class=${classMap({
                    anchor: true,
                    'connected-button': this.connected,
                  })}
                >
                  <svg
                    slot="icon"
                    class="img-responsive"
                    width="27"
                    height="21"
                    viewBox="0 0 19 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Messagerie</title>
                    <path
                      d="M18.0575 0.251526H1.4713C1.11845 0.251526 0.833374 0.535901 0.833374 0.88789V13.6152C0.833374 13.9672 1.11845 14.2515 1.4713 14.2515H18.0575C18.4104 14.2515 18.6954 13.9672 18.6954 13.6152V0.88789C18.6954 0.535901 18.4104 0.251526 18.0575 0.251526ZM17.2601 2.45493V12.8197H2.26872V2.45493L1.7185 2.02738L2.50196 1.02312L3.35519 1.68533H16.1756L17.0288 1.02312L17.8123 2.02738L17.2601 2.45493ZM16.1756 1.68334L9.76441 6.65493L3.3532 1.68334L2.49997 1.02113L1.71651 2.02539L2.26673 2.45295L9.07664 7.73477C9.27248 7.88654 9.51342 7.96893 9.76142 7.96893C10.0094 7.96893 10.2504 7.88654 10.4462 7.73477L17.2601 2.45493L17.8103 2.02738L17.0269 1.02312L16.1756 1.68334Z"
                      fill="#393939"
                    ></path>
                  </svg>
                </nn-mobile-button>

                <nn-mobile-button
                  id="mobile-header-user-link"
                  href="${this.customerUrl}"
                  text="Espace Client"
                  class=${classMap({
                    anchor: true,
                    'connected-button': this.connected,
                  })}
                >
                  <svg
                    slot="icon"
                    class="img-responsive"
                    width="24"
                    height="26"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Espace Client</title>
                    <path
                      d="M8.19337 0.334351C5.93876 0.334351 4.10449 2.22319 4.10449 4.54488C4.10449 6.86656 5.93876 8.7554 8.19337 8.7554C10.448 8.7554 12.2823 6.86656 12.2823 4.54488C12.2823 2.22319 10.448 0.334351 8.19337 0.334351ZM8.19337 7.07119C6.84077 7.07119 5.74004 5.93772 5.74004 4.54488C5.74004 3.15203 6.84077 2.01856 8.19337 2.01856C9.54598 2.01856 10.6467 3.15203 10.6467 4.54488C10.6467 5.93772 9.54598 7.07119 8.19337 7.07119ZM14.7356 16.3344C15.1872 16.3344 15.5534 15.9682 15.5534 15.5166V15.4922C15.5534 12.2426 12.9847 9.59751 9.82893 9.59751H6.55782C3.4012 9.59751 0.833374 12.2426 0.833374 15.4922V15.5166C0.833374 15.9682 1.19951 16.3344 1.65115 16.3344C2.1028 16.3344 2.46893 15.9682 2.46893 15.5166V15.4922C2.46893 13.1706 4.30321 11.2817 6.55782 11.2817H9.82893C12.0835 11.2817 13.9178 13.1706 13.9178 15.4922V15.5166C13.9178 15.9682 14.2839 16.3344 14.7356 16.3344Z"
                      fill="#393939"
                    ></path>
                  </svg>
                </nn-mobile-button>

                <nn-mobile-connection
                  .connected="${this.connected}"
                  user="${this.user}"
                  userId="${this.userId}"
                  customerUrl="${this.customerUrl}"
                  loginUrl="${this.customerUrl}"
                  logoutUrl="${this.logoutUrl}"
                ></nn-mobile-connection>
              </div>
            </div>
            <div class="global-header--toggle_content__header--logo">
              <a
                id="global-header-logo"
                class="global-header_logo"
                href="${this.baseUrl}"
                rel="home"
                aria-current="page"
                title="Nordnet"
              >
                <svg
                  class="img-responsive"
                  width="155"
                  height="28"
                  viewBox="0 0 170 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Nordnet</title>
                  <path
                    d="M111.032 5.26732C111.032 5.26732 111.014 5.26732 111.032 5.26732C109.213 5.34022 107.754 6.05103 106.962 6.7254C106.944 6.7254 106.926 6.74362 106.908 6.74362C106.53 7.03524 106.296 6.7983 106.296 6.50668V5.8141H100.984V29.7084H106.314V14.1069C106.314 11.4824 108.403 9.36817 110.996 9.36817C113.553 9.36817 115.605 11.4277 115.677 13.9976C115.677 14.034 115.677 14.0705 115.677 14.1069V14.1252V29.7084H121.26V13.6695C121.26 7.14459 116.596 4.81167 111.032 5.26732Z"
                    fill="black"
                  ></path>
                  <path
                    d="M20.9062 5.26732C19.0875 5.34022 17.6289 6.05103 16.8366 6.7254C16.8186 6.7254 16.8006 6.74362 16.7826 6.74362C16.4045 7.03524 16.1704 6.7983 16.1704 6.50668V5.8141H10.8763V29.7084H16.2064V14.1069C16.2064 11.4824 18.2952 9.36817 20.8882 9.36817C23.4452 9.36817 25.498 11.4277 25.5701 13.9976C25.5701 14.034 25.5701 14.0705 25.5701 14.1069V14.1252V29.7084H31.1522V13.6695C31.1342 7.14459 26.4704 4.81167 20.9062 5.26732Z"
                    fill="black"
                  ></path>
                  <path
                    d="M64.6094 8.6209C64.5554 8.67558 64.4653 8.74849 64.3753 8.74849C64.2312 8.74849 64.1052 8.63913 64.0872 8.51155V5.8141H58.7751V29.7266H64.0872V21.3791C64.0872 13.469 67.0943 10.3159 72.1903 10.3159H72.9647V5.21264H71.7582C68.4629 5.19441 66.3741 6.16039 64.6094 8.6209Z"
                    fill="black"
                  ></path>
                  <path
                    d="M44.9637 5.21263C38.211 5.21263 32.7189 10.7533 32.7189 17.6063C32.7189 24.4411 38.193 30 44.9637 30C51.7163 30 57.1905 24.4593 57.1905 17.6063C57.1905 10.7533 51.7163 5.21263 44.9637 5.21263ZM44.9457 25.079C40.8581 25.079 37.5448 21.7254 37.5448 17.5881C37.5448 13.4508 40.8581 10.0972 44.9457 10.0972C49.0333 10.0972 52.3466 13.4508 52.3466 17.5881C52.3466 21.7436 49.0333 25.079 44.9457 25.079Z"
                    fill="black"
                  ></path>
                  <path
                    d="M92.5203 7.07169C92.5203 7.25395 92.3763 7.38153 92.2142 7.38153C92.1962 7.38153 92.1782 7.38153 92.1602 7.3633C90.1794 5.97813 87.8025 5.17618 85.2275 5.17618C78.7809 5.17618 73.5049 10.2248 73.0187 16.6221C73.0187 16.6586 73.0187 16.695 73.0007 16.7497C73.0007 16.7679 73.0007 16.7861 73.0007 16.8044C72.9827 16.9502 72.9827 17.1142 72.9827 17.26C72.9827 17.3329 72.9827 17.4058 72.9827 17.497C72.9827 17.5152 72.9827 17.5516 72.9827 17.5699C72.9827 17.6428 72.9827 17.7339 72.9827 17.8068C72.9827 17.9344 72.9827 18.062 73.0007 18.1713C73.0007 18.2078 73.0007 18.2625 73.0007 18.2989C73.3608 24.8056 78.6909 29.9818 85.2095 29.9818C87.7305 29.9818 90.0714 29.2163 92.0341 27.8858C92.0341 27.8858 92.1602 27.8311 92.2142 27.8311C92.3763 27.8311 92.5203 27.9769 92.5203 28.1409V29.7266H97.8504V0H92.5203V7.07169ZM85.1554 25.0243C84.021 25.0243 82.9406 24.7691 81.9862 24.3135C79.5192 23.0923 77.8086 20.5407 77.8086 17.5699C77.8086 13.4326 81.1219 10.079 85.2095 10.079C86.3439 10.079 87.4243 10.3341 88.3787 10.7898C90.8457 12.0109 92.5563 14.5626 92.5563 17.5334C92.5563 21.6707 89.243 25.0243 85.1554 25.0243Z"
                    fill="black"
                  ></path>
                  <path
                    d="M144.669 9.84204C142.418 7.01701 138.997 5.21263 135.143 5.21263C128.39 5.21263 122.898 10.7533 122.898 17.6063C122.898 17.9526 122.916 18.2989 122.934 18.627C123.15 18.8821 123.366 19.1555 123.583 19.3925C126.284 22.8372 130.443 25.0243 135.125 25.0243C131.073 25.0243 127.796 21.7072 127.796 17.6063C127.796 13.5055 131.073 10.1883 135.125 10.1883C137.142 10.1883 138.979 11.0267 140.311 12.3572C139.303 12.74 129.201 16.4581 129.201 16.4581C130.245 17.825 131.524 19.0462 133.018 20.0486L147.064 14.9089C146.668 13.0134 145.821 11.3001 144.669 9.84204Z"
                    fill="black"
                  ></path>
                  <path
                    d="M146.686 19.3742C143.985 22.8189 139.825 25.0243 135.143 25.0243C130.461 25.0243 126.302 22.8372 123.601 19.3925C123.366 19.1373 123.15 18.8821 122.952 18.627C123.474 24.9878 128.733 29.9818 135.143 29.9818C141.554 29.9818 146.83 24.9696 147.334 18.6087C147.118 18.8821 146.902 19.1373 146.686 19.3742Z"
                    fill="#f00"
                  ></path>
                  <path
                    d="M2.77309 29.9818C1.24249 29.9818 0 28.7242 0 27.175C0 25.6258 1.24249 24.3682 2.77309 24.3682C4.30369 24.3682 5.54618 25.6258 5.54618 27.175C5.54618 28.7242 4.30369 29.9818 2.77309 29.9818Z"
                    fill="black"
                  ></path>
                  <path
                    d="M164.333 27.175C164.333 25.6258 165.575 24.3682 167.106 24.3682C168.636 24.3682 169.879 25.6258 169.879 27.175C169.879 28.7242 168.636 29.9818 167.106 29.9818C165.575 29.9818 164.333 28.7242 164.333 27.175Z"
                    fill="black"
                  ></path>
                  <path
                    d="M159.038 24.5869L156.968 24.5687C155.527 24.5687 154.357 23.384 154.375 21.9259V10.6258C154.375 10.48 154.501 10.3706 154.645 10.3524H159.038V5.21265H154.663C154.519 5.21265 154.393 5.08506 154.375 4.93926V0.0182343H149.063V19.8664V22.1993V22.2175C149.063 26.9198 152.196 29.9818 156.716 29.9818H159.02V29.9636H159.038V24.5869Z"
                    fill="black"
                  ></path>
                </svg>
              </a>
            </div>
            <ul class="global-header--toggle_content__body menu">
              <li
                class=${classMap({
                  'global-header--toggle_content__body--item has-submenu': true,
                  active: this.activeItem === 'internet',
                  'dropdown-open': this.drop,
                })}
              >
                <a
                  id="header-internet-link"
                  class=${classMap({
                    'has-submenu--toggle': true,
                    active: this.drop,
                  })}
                  href="${this.baseUrl}/connexion-internet"
                  title="Accès Internet"
                  aria-haspopup="true"
                  aria-expanded="${this.drop}"
                  @click="${this._dropdown}"
                  >Accès Internet</a
                >
                <!--  TODO : PB d'accessibilité : le dropdown ne se ferme pas au clavier -->
                <ul
                  class=${classMap({
                    'has-submenu--content': true,
                    'dropdown-close': !this.drop,
                    'dropdown-open': this.drop,
                  })}
                >
                  <li>
                    <a
                      href="${this
                        .baseUrl}/connexion-internet/internet-satellite"
                      id="header-internet-satellite-link"
                      >Internet Satellite</a
                    >
                  </li>
                  <li>
                    <a
                      href="${this
                        .baseUrl}/connexion-internet/internet-satellite-pro"
                      id="header-internet-satellite-pro-link"
                      >Internet Satellite pour les pros</a
                    >
                  </li>
                  <li>
                    <a
                      href="${this.baseUrl}/connexion-internet/internet-fibre"
                      id="header-internet-fibre-link"
                      >Internet Fibre</a
                    >
                  </li>
                  <li>
                    <a
                      href="${this.baseUrl}/connexion-internet/internet-4G"
                      id="header-internet-4g-link"
                      >Internet 4G</a
                    >
                  </li>
                  <li>
                    <a
                      href="${this.baseUrl}/connexion-internet/internet-radio"
                      id="header-internet-radio-link"
                      >Internet Radio</a
                    >
                  </li>
                  <li>
                    <a
                      href="${this.baseUrl}/connexion-internet/internet-adsl"
                      id="header-internet-adsl-link"
                      >Internet ADSL</a
                    >
                  </li>
                </ul>
              </li>
              <li
                class=${classMap({
                  'global-header--toggle_content__body--item': true,
                  active: this.activeItem === 'mvno',
                })}
              >
                <a
                  id="header-mobile-link"
                  href="${this.baseUrl}/forfaits-mobile"
                  title="Forfaits mobile"
                  >Forfaits mobile</a
                >
              </li>
              <li
                class=${classMap({
                  'global-header--toggle_content__body--item': true,
                  active: this.activeItem === 'securite',
                })}
              >
                <a
                  id="header-securite-link"
                  href="${this.baseUrl}/securite"
                  title="Sécurité"
                  >Antivirus &amp; VPN</a
                >
              </li>
              <li
                class=${classMap({
                  'global-header--toggle_content__body--item': true,
                  active: this.activeItem === 'ndd',
                })}
              >
                <a
                  id="header-domain-link"
                  href="${this.baseUrl}/nom-de-domaine"
                  title="Noms de domaine"
                  >Noms de domaine</a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    `;
  }

  _toggle(e: Event) {
    // menu mobile
    e.stopPropagation();
    this.open = !this.open;
    // TODO : au niveau du consommateur, ne pas oublier d'ajouter du code CSS pour placer un overflow:hidden sur la balise html quand le menu est ouvert en version mobile avec la class "overflowed"
    const htmlPage = document.querySelector('html');
    if (htmlPage) {
      if (this.open) {
        htmlPage.classList.add('overflowed');
      } else {
        htmlPage.classList.remove('overflowed');
      }
    }
  }

  _dropdown(e: Event) {
    // dropdown menu
    e.preventDefault();
    this.drop = !this.drop;
    // TODO : ajouter du code pour fermer le menu quand on clique à l'extérieur du dropdown
    // si je click sur le dropdown
    // le sous-menu s'ouvre
    // alors
    // je cherche la balise 'html'
    // et si je click dessus
    // alors
    // je trigger le click sur le dropdown
    // et normalement le sous-menu se ferme
  }
}
